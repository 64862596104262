
import { Emitter, computed, defineComponent, inject, onMounted, ref } from 'vue'
// import ImageVerify from '@/components/dialog/dialog-image-verification.vue'
import { useI18n } from 'vue-i18n'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { fetch } from '@/api'
import { getValueByKey } from '@/utils/api'
import { LoginData } from '@/types/LoginData'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import CheckBox from '@/components/custom-controls/check-box.vue'

export default defineComponent({
    components: {
        // ImageVerify
        CheckBox
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const router = useRouter()
        const showVerify = ref(false)
        const action = ref(0)
        const { t } = useI18n()
        const store = useStore()

        const pwd = ref<string>('')
        const uname = ref<string>('')
        const rememberUser = ref(false)

        // const hideVerify = () => {
        //     showVerify.value = false
        // }

        onMounted(() => {
            if (lastUserName.value && lastUserPwd.value) {
                pwd.value = lastUserPwd.value
                uname.value = lastUserName.value
                rememberUser.value = true
            }
        })

        const doLogin = () => {
            // showVerify.value = false
            if (action.value === 0) {
                // do login
                if (uname.value.length === 0) {
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('usernamerequired'))

                    return
                }
                if (pwd.value.length === 0) {
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('passwordrequired'))

                    return
                }

                const params = {
                    ...OtherApiParams,
                    act: ACT.LOGIN,
                    username: uname.value,
                    pwd: pwd.value
                }

                requestLogin(params)
            } else {
                // trial login
                const params = {
                    ...OtherApiParams,
                    act: ACT.TRIAL
                }

                requestLogin(params)
            }
        }

        const doTrylogin = () => {
            action.value = 1
            // veryFyHumanLogin()
            doLogin()
        }

        const requestLogin = (params: any) => {
            store.commit(AppMutation.SHOW_LOADING)

            store
                .dispatch(AppActions.FETCH_DATA, params)
                .then((_data) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (_data.includes('err=')) {
                        const errorCode = getValueByKey(_data, 'err')
                        let errorMessage = ''

                        if (errorCode === '-1') {
                            errorMessage = t('invalidparams')
                        } else if (errorCode === '-2') {
                            errorMessage = t('invalidusernameandpassword')
                            // '用户或密码错误'
                        } else if (errorCode === '-3') {
                            errorMessage = t('accountislocked')
                        } else if (errorCode === '0') {
                            errorMessage = t('unknowerror')
                        } else {
                            // -4
                            errorMessage = '账号已经登录，请稍后尝试'
                        }
                        emitter.emit(EventBuss.TOAST_MESSAGE, errorMessage)
                    } else {
                        let {
                            sid,
                            username,
                            userPoint,
                            userLimitList,
                            notice
                        } = LoginData.Parse(_data)

                        if (!username) username = uname.value

                        const userSession = {
                            token: String(sid),
                            username: String(username),
                            point: userPoint,
                            limit: userLimitList,
                            notice: String(notice)
                        }

                        store.commit(
                            AppMutation.INIT_USER_SESSTION,
                            userSession
                        )

                        if (action.value === 0 && rememberUser.value) {
                            store.commit(AppMutation.SET_LAST_USER, {
                                u: uname.value,
                                p: pwd.value
                            })
                        } else if (action.value === 0 && !rememberUser.value) {
                            store.commit(AppMutation.CLEAR_LAST_USER)
                        } else if (action.value === 1) {
                            store.commit(AppMutation.CLEAR_LAST_USER)
                        }

                        router.push({ name: ROUTES.LOBY })
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))
                })
        }

        // const veryFyHumanLogin = () => {
        //     if (document.activeElement != document.body) {
        //         const focusedElement = document.activeElement as HTMLElement
        //         focusedElement.blur()
        //     }
        //     showVerify.value = true
        // }

        const loginEnabled = computed(
            () => pwd.value.length > 0 && uname.value.length > 0
        )

        const lastUserName = computed(() => store.getters['lastUserName'])
        const lastUserPwd = computed(() => store.getters['lastUserPwd'])

        return {
            showVerify,
            rememberUser,
            pwd,
            uname,
            loginEnabled,
            // veryFyHumanLogin,
            // hideVerify,
            doLogin,
            doTrylogin
        }
    }
})
