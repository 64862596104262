import { CookiesToken } from '@/types/CookiesToken'
import { getBrowserLanguage } from '@/utils'
import { AppState, InjectionKey, State, UserState } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'

import { app } from './modules/app'
import { user } from './modules/user'
import { AppMutation } from './types'
import { Dictionary } from '@/types/dictionary'
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        version: '1.0.1',
        root: true
    },
    mutations: {
        [AppMutation.CLEAR_ALL](state) {
            window.sessionStorage.clear()

            const appState = app.state as AppState
            const userState = user.state as UserState

            appState.soundVolume = 1
            appState.video = 1

            appState.chips = []

            userState.deskNotice = ''
            userState.lobyNotice = ''
            userState.point = 0
            userState.resultCache = new Dictionary<string>()
            userState.token = undefined
            userState.username = undefined
        }
    },
    actions: {},
    modules: {
        app,
        user
    }
})

export function useStore(): Store<State> {
    return baseUseStore(key)
}
