
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { Emitter, computed, defineComponent, inject, onMounted, ref } from 'vue'
import {
    SVGLanguage,
    SVGArrow,
    SVGExclamation,
    SVGMessage,
    SVGDolar2,
    SVGChip,
    SVGExit,
    SVGSpeaker
} from '@/assets/svg'
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { useI18n } from 'vue-i18n'
import SwitchControl from '@/components/custom-controls/switch-control.vue'
import audioPlayer from '@/utils/sounds'
export default defineComponent({
    components: {
        'icon-lang': SVGLanguage,
        'icon-arrow': SVGArrow,
        'icon-rule': SVGExclamation,
        'icon-cs': SVGMessage,
        'icon-record': SVGDolar2,
        'icon-chip': SVGChip,
        'icon-exit': SVGExit,
        'icon-speaker': SVGSpeaker,
        'switch-control': SwitchControl
    },
    setup() {
        const { t } = useI18n()
        const hide = ref(false)
        const emitter = inject('emitter') as Emitter
        const router = useRouter()
        const store = useStore()

        const soundIsOn = ref(false)
        const videoIsOn = ref(false)

        const activeMenu = ref(-1)

        const toggleMenu = (num: number) => {
            if (activeMenu.value === num) activeMenu.value = -1
            else activeMenu.value = num
        }

        onMounted(() => {
            soundIsOn.value = sound.value
            videoIsOn.value = video.value
        })

        const showDialog = (e: DialogBoxName) => {
            close()
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: e
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const openCustumerService = () => {
            close()
            window.open(process.env.CUSTUMER_SERVICE, '_blank')
        }

        const logOut = () => {
            store.commit(AppMutation.SHOW_LOADING)
            const _params = {
                ...OtherApiParams,
                act: ACT.LOG_OUT,
                username: user.value
            }

            store
                .dispatch(AppActions.FETCH_DATA, _params)
                .then(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    store.commit(AppMutation.CLEAR_ALL)
                    router.push({ name: ROUTES.LOGIN })
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, '网络错误')
                })
        }

        const close = () => {
            hide.value = true
            setTimeout(() => {
                const event: MessageBoxEvent = {
                    type: MessageBoxActionType.close,
                    name: 'setting'
                }
                emitter.emit(EventBuss.DIALOG, event)
            }, 300)
        }

        const exitGame = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'confirm-cancel-message'
            }
            emitter.emit(EventBuss.DIALOG, _evt)
            close()
            setTimeout(() => {
                emitter.emit(EventBuss.CONFIRM_CANCEL_CONTENT, {
                    message:
                        '您确定要登出游戏吗，您未结算 的投注系统将为您自动结算。',
                    callback: logOut
                })
            }, 10)
        }

        const toggleVideo = (event: boolean) => {
            videoIsOn.value = event
            if (event) {
                store.commit(AppMutation.SET_VIDEO, 1)
            } else {
                audioPlayer.isOn = 0
                store.commit(AppMutation.SET_VIDEO, 0)
            }
        }
        const toggleMusic = (event: boolean) => {
            soundIsOn.value = event
            if (event) {
                audioPlayer.isOn = 1
                store.commit(AppMutation.SET_SOUND, 1)
            } else {
                audioPlayer.isOn = 0
                store.commit(AppMutation.SET_SOUND, 0)
            }
        }

        const user = computed(() => store.getters['user'])
        const sound = computed(() => store.getters['soundEffect'] === 1)
        const video = computed(() => store.getters['videoIsOn'])

        return {
            t,
            close,
            toggleMenu,
            exitGame,
            showDialog,
            openCustumerService,
            toggleVideo,
            toggleMusic,
            activeMenu,
            hide,
            soundIsOn,
            videoIsOn
        }
    }
})
