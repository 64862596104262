import { fetch } from '@/api'
import { CookiesToken } from '@/types/CookiesToken'
import { getCookieValue, parseBool, setCookieValue } from '@/utils'
import { State, TableLimit, UserState } from 'vue'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { AppActions, AppMutation } from '../types'
import { Dictionary } from '@/types/dictionary'
import { ungzip } from 'pako'

import CryptoJS from 'crypto-js'
import { ACT } from '@/types/global'

const state: UserState = {
    username: getCookieValue(CookiesToken.USER_NAME)?.b64decode() || undefined,
    token: getCookieValue(CookiesToken.TOKEN)?.b64decode() || undefined,
    point: parseInt(getCookieValue(CookiesToken.POINT) || '0'),
    lobyNotice: getCookieValue(CookiesToken.LOBY_NOTICE) || '',
    deskNotice: getCookieValue(CookiesToken.DESK_NOTICE) || '',
    limits: getCookieValue(CookiesToken.LIMITS)?.toObject<TableLimit[]>() || [],
    limit:
        getCookieValue(CookiesToken.LIMIT)?.toObject<TableLimit>() || undefined,
    resultCache: new Dictionary<string>(),
    avatar: parseInt(getCookieValue(CookiesToken.AVATAR) || '1'),
    lastUser: localStorage.getItem('lastUser') || undefined,
    lastUserPwd: localStorage.getItem('lastUserPWD') || undefined,
    rememberPassword: parseInt(
        getCookieValue(CookiesToken.REMEMBER_PASSWORD) || '0'
    )
}

const mutations: MutationTree<UserState> = {
    [AppMutation.INIT_USER_SESSTION](
        state,
        payloads: {
            token: string
            username: string
            point: number
            limit: TableLimit[]
            notice: string
        }
    ) {
        state.token = payloads.token
        state.username = payloads.username
        state.point = payloads.point
        state.limits = payloads.limit
        state.lobyNotice = payloads.notice

        setCookieValue(CookiesToken.TOKEN, payloads.token.b64encode())
        setCookieValue(CookiesToken.USER_NAME, payloads.username.b64encode())
        setCookieValue(CookiesToken.POINT, String(payloads.point))

        setCookieValue(CookiesToken.LIMITS, JSON.stringify(payloads.limit))
        setCookieValue(CookiesToken.LOBY_NOTICE, payloads.notice)
    },

    [AppMutation.SET_USER](state, payload: string) {
        state.username = payload
        setCookieValue(CookiesToken.USER_NAME, payload.b64encode())
    },
    [AppMutation.SET_COIN](state, payload: number) {
        state.point = payload
        setCookieValue(CookiesToken.POINT, String(payload))
    },
    [AppMutation.SET_DESK_NOTICE](state, payload) {
        state.deskNotice = payload
        setCookieValue(CookiesToken.DESK_NOTICE, payload)
    },
    [AppMutation.ADD_RESULT_TO_CACHE](
        state,
        payload: { key: string; value: string }
    ) {
        state.resultCache.Add(payload.key, payload.value)

        if (state.resultCache.Count() > 15) {
            state.resultCache.Remove(state.resultCache.Keys()[0])
        }
    },
    [AppMutation.SET_AVATAR](state, payload: number) {
        state.avatar = payload
        setCookieValue(CookiesToken.AVATAR, String(payload))
    },
    [AppMutation.SET_LIMIT](state, payload) {
        state.limit = payload
        setCookieValue(CookiesToken.LIMIT, JSON.stringify(payload))
    },
    [AppMutation.SET_LAST_USER](state, payload: { u: string; p: string }) {
        const p = CryptoJS.AES.encrypt(payload.p, ACT.PWD_KEY).toString()
        state.lastUser = payload.u
        state.lastUserPwd = p
        state.rememberPassword = 1

        localStorage.setItem('lastUser', payload.u)
        localStorage.setItem('lastUserPWD', p)
    },
    [AppMutation.CLEAR_LAST_USER](state) {
        state.lastUser = undefined
        state.lastUserPwd = undefined
        state.rememberPassword = 0
        localStorage.clear()
    }
}

const actions: ActionTree<UserState, State> = {
    [AppActions.FETCH_DATA]({ ...args }, params): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            fetch(params)
                .then((response) => {
                    const compressedData = new Uint8Array(response.data)
                    const unzippedData = ungzip(compressedData, {
                        to: 'string'
                    })

                    resolve(unzippedData.toString())
                })
                .catch((e) => {
                    console.log(e)
                    reject('网络错误')
                })
        })
    }
}

const getters: GetterTree<UserState, State> = {
    token(state) {
        return state.token
    },
    user(state) {
        return state.username
    },
    coin(state) {
        return state.point
    },
    lobyNotice(state) {
        return state.lobyNotice
    },
    resultCache(state) {
        return state.resultCache
    },
    avatar(state) {
        return state.avatar
    },
    limits(state) {
        return state.limits
    },
    xian(state) {
        return state.limit
    },
    lastUserName(state) {
        return state.lastUser
    },
    lastUserPwd(state) {
        if (state.lastUserPwd) {
            return CryptoJS.AES.decrypt(
                state.lastUserPwd,
                ACT.PWD_KEY
            ).toString(CryptoJS.enc.Utf8)
        }
        return undefined
    },
    rememberPassword(state) {
        return state.rememberPassword === 1
    }
}

export const user: Module<UserState, State> = {
    state,
    mutations,
    actions,
    getters
}
