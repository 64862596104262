import { createI18n } from 'vue-i18n'
import { CookiesToken } from '@/types/CookiesToken'
import { getBrowserLanguage, getCookieValue } from '.'

const messages = {
    en: {
        done: 'Done',
        trial: 'Trial',
        login: 'Login',
        lang: 'English',
        usernameplaceholder: 'Please enter your username',
        passwordplaceholder: 'Enter your password',
        usernamerequired: 'Please enter username',
        passwordrequired: 'Please enter password',
        invalidparams: 'Invalid parameter',
        invalidusernameandpassword: 'Invalid username or password',
        accountislocked: 'Account is locked',
        unknowerror: 'Unknown error',
        accountislogedin: 'Account is already logged in, please try later',
        networkerror: 'Network error',
        bankerabt: 'B',
        playerabt: 'P',
        tieabt: 'T',
        dragonabt: 'D',
        tigerabt: 'T',
        bankerpairabt: 'BP',
        playerpairabt: 'PP',
        baccarattable: 'BACT',
        dragontigertable: 'D.Tiger',
        changepwd: 'Change Pwd',
        oldpwd: 'Old Password',
        newpwd: 'New Password',
        confirmpwd: 'Confirm Password',
        confirm: 'Confirm',
        passworderror:
            'Old password is invalid or new password is inconsistent',
        modifyok: 'Successfully modified',
        record: 'Betting Record',
        startdate: 'Start Date',
        inquire: 'Inquire',
        enddate: 'End Date',
        datesinvalid: 'Invalid Dates',
        systemsetting: 'System Setting',
        on: 'On',
        off: 'Off',
        video: 'Video',
        soundeffect: 'Sound Effect',
        vipservice: 'VIP Service',
        gamerules: 'Game Rules',
        system: 'System Setting',
        exit: 'Exit',
        shoeround: 'Shoes',
        allgames: 'All Games',
        baccarat: 'BAC',
        dragontiger: 'D.Tiger',
        tablelimit: 'Table Limit',
        betratio: '{0} to {1}',
        youareinthistable: 'You are at this table',
        banker: 'B',
        player: 'P',
        tie: 'Tie',
        playerpair: 'P.Pair',
        bankerpair: 'B.Pair',
        tiger: 'T',
        dragon: 'D',
        bet: 'BET',
        ingame: 'NO BET',
        sessionexpired: 'You have been logged out due to inactivity',
        cancelbet: 'Cancel Bet',
        confirmbet: 'Confirm Bet',
        editcustomchip: 'Customize Chip',
        nochipisselected: 'Please Select Chips',
        balanceshort: 'Insufficient balance',
        abovemaximum: 'Above Max Limit',
        languagetitle: 'Language',
        customchiptitle: 'Custom Chip',
        pleasenterachipsvalue: "Please enter a chip's value",
        betsuccess: 'Bet Successful',
        customizechip: 'Custom',
        limittitle: 'Table Limit',
        rotationmessage:
            "We don't support landscape mode yet. Please go back to portrait mode for the best experience.",
        norecordfound: 'No record found.',
        bettime: 'Bet Date',
        winOrLost: 'Win/Lose',
        betamount: 'Bet Amount',
        betType: 'Bet type',
        gameresult: 'Game result',
        table: 'Table',
        gameno: 'Game No.',
        time: 'Time',
        count: 'Count',
        totalinvest: 'Total Invest',
        totalwin: 'Total Win',
        wsbettingfee: 'WS  Bet Fee',
        codewashfee: 'Code Wash Fee',
        summary: 'Summary',
        details: 'Details',
        placebet: 'Start Betting',
        stopbet: 'Stop Betting',
        gameid: 'Game ID',
        startplay: 'PLAY',
        selectlimit: 'SELECT LIMIT',
        switchtable: 'DESKS',
        preask: '.ASK',
        win: 'WIN',
        settlement: 'Settlement',
        // additional
        username: 'User',
        password: 'Password',
        signout: 'Sign out',
        account: 'Account',
        tablenumber: 'Table No.',
        backtoloby: 'Loby',
        playerbanker: 'B/P',
        tielimit: 'Tie',
        pairabt: 'PAIR',
        minlimit: 'MIN',
        maxlimit: 'MAX',
        nextgame: 'NEXT ',
        dragonandtiger: 'Dragon/Tiger',
        download: 'DOWNLOAD',
        csservice: 'C.SERVICE',
        //additional
        bankerplayerlimitlongtext: 'B/P LIMIT',
        dragontigerlimitlongtext: 'D.Tiger LIMIT',
        tielimitlongtext: 'Tie LIMIT',
        pairlongtext: 'PAIR LIMIT',
        exitok: 'Exit successfully'
    },
    'zh-cn': {
        done: '完毕',
        trial: '试玩游戏',
        login: '登录系统',
        lang: '中文简体',
        usernameplaceholder: '请输入您的用户名',
        passwordplaceholder: '输入您的密码',
        usernamerequired: '请输入用户名',
        passwordrequired: '请输入密码',
        invalidparams: '参数传递错误',
        invalidusernameandpassword: '用户名或密码输入错误',
        accountislocked: '账号或上级代理被锁定',
        unknowerror: '未知错误',
        accountislogedin: '账号已经登录，请稍后尝试',
        networkerror: '网络错误',
        bankerabt: '庄',
        playerabt: '闲',
        tieabt: '和',
        dragonabt: '龙',
        tigerabt: '虎',
        bankerpairabt: '对',
        playerpairabt: '对',
        baccarattable: '百家乐',
        dragontigertable: '龙虎',
        changepwd: '修改密码',
        oldpwd: '原始密码',
        newpwd: '新密码',
        confirmpwd: '确认密码',
        confirm: '确 定',
        passworderror: '原密码错误或新密码不一致',
        modifyok: '修改成功',
        record: '投注记录',
        startdate: '开始日期',
        inquire: '查询',
        enddate: '结束时间',
        datesinvalid: '无效日期',
        systemsetting: '系统设置',
        on: '开',
        off: '关',
        video: '视频',
        soundeffect: '音效',
        vipservice: '贵宾服务',
        gamerules: '游戏规则',
        system: '系统设定',
        exit: '退出游戏',
        shoeround: '靴局',
        allgames: '全部游戏',
        baccarat: '百家乐',
        dragontiger: '龙虎',
        tablelimit: '限红',
        betratio: '{0} 赔 {1}',
        youareinthistable: '您在此桌',
        banker: '庄',
        player: '闲',
        tie: '和',
        playerpair: '闲对',
        bankerpair: '庄对',
        tiger: '虎',
        dragon: '龙',
        bet: '请下注',
        ingame: '结算中',
        sessionexpired: '您因不活动而被注销',
        cancelbet: '取消下注',
        confirmbet: '确认下注',
        editcustomchip: '编辑自定义金额',
        nochipisselected: '请先选择筹码再投注',
        balanceshort: '余额不足',
        abovemaximum: '超过最大限红',
        languagetitle: '语言选择',
        customchiptitle: '自定义筹码',
        pleasenterachipsvalue: '请输入筹码的值。',
        betsuccess: '下注成功',
        customizechip: '自定义',
        limittitle: '限红',
        rotationmessage:
            '我们还不支持横向模式。 请返回纵向模式以获得最佳体验。',
        norecordfound: '没有查询到记录',
        bettime: '投注时间',
        winOrLost: '输赢',
        betamount: '投注金额',
        betType: '投注类型',
        gameresult: '游戏结果',
        table: '桌台',
        gameno: '游戏编号',
        time: '时间',
        count: '笔数',
        totalinvest: '总投',
        totalwin: '总赢',
        wsbettingfee: '洗码费投注',
        codewashfee: '洗码费',
        summary: '小结',
        details: '详细',
        placebet: '开始接受投注',
        stopbet: '停止投注',
        gameid: '游戏ID',
        startplay: '开始游戏',
        selectlimit: '选择限红',
        switchtable: '切换桌台',
        preask: '问路',
        win: '赢',
        settlement: '结算中',
        username: '用户名',
        password: '密   码',
        signout: '登 出',
        account: '账号',
        tablenumber: '桌号',
        backtoloby: '返回大厅',
        playerbanker: '庄闲',
        tielimit: '和局',
        pairabt: '对子',
        minlimit: '最低',
        maxlimit: '最高',
        nextgame: '下局开',
        dragonandtiger: '龙/虎',
        download: '手机版下载',
        csservice: '服务中心',
        // additional
        bankerplayerlimitlongtext: '庄闲限红',
        dragontigerlimitlongtext: '龙虎限红',
        tielimitlongtext: '和局限红',
        pairlongtext: '对子限红',
        exitok: '成功退出'
    },
    kor: {
        done: '완료',
        trial: '게임을 시도',
        login: '로그인 시스템',
        lang: '한국인',
        usernameplaceholder: '사용자 이름을 입력하세요.',
        passwordplaceholder: '비밀번호를 입력하세요',
        usernamerequired: '사용자 이름을 입력하십시오',
        passwordrequired: '비밀번호를 입력 해주세요',
        invalidparams: '매개변수 전달 오류',
        invalidusernameandpassword: '잘못된 사용자 이름 또는 비밀번호',
        accountislocked: '계정 또는 상위 에이전트가 잠겨 있습니다.',
        unknowerror: '알 수 없는 실수',
        accountislogedin:
            '계정이 이미 로그인되어 있습니다. 나중에 시도하십시오.',
        networkerror: '네트워크 오류',
        bankerabt: 'B',
        playerabt: 'P',
        tieabt: 'T',
        dragonabt: 'D',
        tigerabt: 'T',
        bankerpairabt: 'BP',
        playerpairabt: 'PP',
        baccarattable: '바카라',
        dragontigertable: 'D.TIGER',
        changepwd: '비밀번호 변경',
        oldpwd: '원래 비밀번호',
        newpwd: '새 비밀번호',
        confirmpwd: '비밀번호 확인',
        confirm: '확신하는',
        passworderror: '원래 암호가 잘못되었거나 새 암호가 일치하지 않습니다.',
        modifyok: '성공적으로 수정됨',
        record: '베팅 기록',
        startdate: '시작일',
        inquire: '문의',
        enddate: '종료 시간',
        datesinvalid: '잘못된 날짜',
        systemsetting: '환경 설정',
        on: '켜짐',
        off: '끄다',
        video: '동영상',
        soundeffect: '음향 효과',
        vipservice: 'VIP 서비스',
        gamerules: '게임 규칙',
        system: '시스템 프로그램',
        exit: '게임을 종료',
        shoeround: '부팅 게임',
        allgames: '모든 게임들',
        baccarat: '바카라',
        dragontiger: '드래곤 타이거',
        tablelimit: '빨간색 한계',
        betratio: '{0} ~ {1}',
        youareinthistable: '당신은 이 테이블에 있습니다',
        banker: 'B',
        player: 'P',
        tie: '묶다',
        playerpair: 'P.Pair',
        bankerpair: 'B.Pair',
        tiger: 'T',
        dragon: 'D',
        bet: '내기',
        ingame: '게임에서',
        sessionexpired: '활동이 없어 로그아웃되었습니다.',
        cancelbet: '내기를 취소',
        confirmbet: '베팅 확인',
        editcustomchip: '맞춤 금액 수정',
        nochipisselected: '베팅하기 전에 칩을 선택하십시오',
        balanceshort: '잔액 불충분',
        abovemaximum: '최대 빨간색 제한을 초과했습니다.',
        languagetitle: '언어 선택',
        customchiptitle: '맞춤형 칩',
        pleasenterachipsvalue: '칩 값을 입력하세요.',
        betsuccess: '성공적인 내기',
        customizechip: '사용자 정의',
        limittitle: '빨간색 한계',
        rotationmessage:
            '가로 모드는 아직 지원하지 않습니다. 최상의 경험을 위해 세로 모드로 돌아가십시오.',
        norecordfound: '기록을 찾을 수 없습니다',
        bettime: '내기 시간',
        winOrLost: '이기거나 지거나',
        betamount: '베팅 금액',
        betType: '내기 유형',
        gameresult: '게임 결과',
        table: '테이블',
        gameno: '게임 번호',
        time: '시각',
        count: '펜 수',
        totalinvest: '총 투자',
        totalwin: '총 승리',
        wsbettingfee: '세탁비 베팅',
        codewashfee: '코드 워시 요금',
        summary: '요약',
        details: '상세한',
        placebet: '베팅 수락 시작',
        stopbet: '내기를 중지',
        gameid: '게임 ID',
        startplay: '플레이',
        selectlimit: '제한선택',
        switchtable: '테이블',
        preask: '.ASK',
        win: 'WIN',
        settlement: '합의',
        username: '비밀번호',
        password: '비밀번호',
        signout: '로그아웃',
        account: '계정',
        tablenumber: '테이블',
        backtoloby: '로비',
        playerbanker: 'B/P',
        tielimit: '묶다',
        pairabt: '쌍',
        minlimit: '분',
        maxlimit: '최대',
        nextgame: '다음 게임',
        dragonandtiger: 'D/T',
        download: '다운로드',
        csservice: '서비스 센터',
        // additional
        bankerplayerlimitlongtext: 'B/P 빨간색 한계',
        dragontigerlimitlongtext: 'D/T 빨간색 한계',
        tielimitlongtext: '및 제한된 빨간색',
        pairlongtext: '페어 리미트 레드',
        exitok: '성공적으로 종료'
    }
}

const i18n = createI18n({
    legacy: false,
    locale: getCookieValue(CookiesToken.LANG) || getBrowserLanguage(),
    fallbackLocale: 'zh-cn',
    globalInjection: true,
    warnHtmlInMessage: false,
    warnHtmlMessage: false,
    messages
})

export default i18n
