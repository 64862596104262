export enum AppMutation {
    SET_ORIENTATION = 'SET_ORIENTATION',
    HIDE_LOADING = 'HIDE_LOADING',
    SHOW_LOADING = 'SHOW_LOADING',
    SET_SOUND = 'SET_SOUND',
    SET_USER_INTERACTION = 'SET_USER_INTERACTION',
    SET_LANG = 'SET_LANG',
    SET_FULL_SCREEN = 'SET_FULL_SCREEN',
    SET_VIDEO = 'SET_VIDEO',
    SET_USER = 'SET_USER',
    CLEAR_ALL = 'CLEAR_ALL',
    INIT_USER_SESSTION = 'INIT_USER_SESSTION',
    SET_COIN = 'SET_COIN',
    SET_DESK_NOTICE = 'SET_DESK_NOTICE',
    ADD_RESULT_TO_CACHE = 'ADD_RESULT_TO_CACHE',
    SET_AVATAR = 'SET_AVATAR',
    SET_LIMIT = 'SET_LIMIT',
    SET_CHIPS = 'SET_CHIPS',
    SET_CURRENT_CHIPS = 'SET_CURRENT_CHIPS',
    SET_CUSTOM_CHIP = 'SET_CUSTOM_CHIP',
    SET_LAST_USER = 'SET_LAST_USER',
    CLEAR_LAST_USER = 'CLEAR_LAST_USER'
}

export enum AppActions {
    FETCH_DATA = 'FETCH_DATA'
}
