
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const emitter = inject('emitter') as Emitter
        const close = () => {
            const _e: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'rule'
            }

            emitter.emit(EventBuss.DIALOG, _e)
        }
        return {
            close
        }
    }
})
