export enum CookiesToken {
    SOUND = 'SOUND',
    LANG = 'LANG',
    VIDEO = 'VIDEO',
    USER_NAME = 'USER_NAME',
    CHIP_ITEMS = 'CHIP_ITEMS',
    SOUND_VOLUME = 'SOUND_VOLUME',
    TOKEN = 'TOKEN',
    POINT = 'POINT',
    LOBY_NOTICE = 'LOBY_NOTICE',
    DESK_NOTICE = 'DESK_NOTICE',
    LIMITS = 'LIMITS',
    LIMIT = 'LIMIT',
    AVATAR = 'AVATAR',
    CURRENT_CHIP = 'CURRENT_CHIP',
    CUSTOM_CHIP = 'CUSTOM_CHIP',
    REMEMBER_PASSWORD = 'REMEMBER_PASSWORD'
}
