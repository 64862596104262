enum MessageBoxActionType {
    close = 'close',
    open = 'open'
}

type MessageBoxEvent = {
    type: MessageBoxActionType
    message?: string | unknown
    name: DialogBoxName
}

type DialogBoxName =
    | 'setting'
    | 'others'
    | 'avatar'
    | 'confirm-cancel-message'
    | 'password'
    | 'record'
    | 'rule'

export { MessageBoxActionType, MessageBoxEvent, DialogBoxName }
