import { Dictionary } from '@/types/dictionary'

class Sound {
    src: string

    /**
     *
     */
    constructor(fileName: string) {
        this.src = require(`@/assets/sounds/${fileName}.mp3`)
    }
}

export const SOUNDKEY = {
    TICK: 'ticker',
    START_BET: 'start_bet',
    STOP_BET: 'stop_bet',
    BANKER_WIN: 'banker_win',
    PLAYER_WIN: 'player_win',
    TIE: 'tie',
    DRAGON_WIN: 'dragon_win',
    TIGER_WIN: 'tiger_win',
    BANKER_WIN_BANKER_PAIR: 'banker_win_banker_pair',
    BANKER_WIN_BOTH_PAIR: 'banker_win_both_pair',
    BANKER_WIN_PLAYER_PAIR: 'banker_win_player_pair',
    PLAYER_WIN_BOTH_PAIR: 'player_win_both_pair',
    PLAYER_WIN_BANKER_PAIR: 'player_win_banker_pair',
    PLAYER_WIN_PLAYER_PAIR: 'player_win_player_pair',
    TIE_BOTH_PAIR: 'tie_both_pair',
    TIE_PLAYER_PAIR: 'tie_player_pair',
    TIE_BANKER_PAIR: 'tie_banker_pair',
    BET_PLACE: 'chip2',
    BET_SUCCESS: 'bet_success',
    FANTAN_1: 'fantan1',
    FANTAN_2: 'fantan2',
    FANTAN_3: 'fantan3',
    FANTAN_4: 'fantan4'
}
export class AudioManager {
    soundDictionary: Dictionary<Sound> = new Dictionary<Sound>()
    sounds = new Array<HTMLAudioElement>()
    soundsVolume = 1
    isLoaded = false

    isOn = 1
    soundEffect = 1
    isSoundInitialized = false
    musicVolume = 1

    LoadSounds(): void {
        this.sounds = new Array<HTMLAudioElement>()

        for (let i = 1; i <= 6; i++) {
            this.sounds[i - 1] = document.getElementById(
                `audio${i}`
            ) as HTMLAudioElement
            this.sounds[i - 1].load()
        }

        this.soundDictionary.Add(SOUNDKEY.TICK, new Sound('di2'))
        this.soundDictionary.Add(SOUNDKEY.START_BET, new Sound('start_bet'))
        this.soundDictionary.Add(SOUNDKEY.STOP_BET, new Sound('stop_bet'))
        this.soundDictionary.Add(SOUNDKEY.PLAYER_WIN, new Sound('player_win'))
        this.soundDictionary.Add(
            SOUNDKEY.PLAYER_WIN_BANKER_PAIR,
            new Sound('p_bpair_win')
        )
        this.soundDictionary.Add(
            SOUNDKEY.PLAYER_WIN_BOTH_PAIR,
            new Sound('p_bppair_win')
        )
        this.soundDictionary.Add(
            SOUNDKEY.PLAYER_WIN_PLAYER_PAIR,
            new Sound('p_ppair_win')
        )
        this.soundDictionary.Add(SOUNDKEY.BANKER_WIN, new Sound('banker_win'))
        this.soundDictionary.Add(
            SOUNDKEY.BANKER_WIN_BANKER_PAIR,
            new Sound('b_bpair_win')
        )
        this.soundDictionary.Add(
            SOUNDKEY.BANKER_WIN_BOTH_PAIR,
            new Sound('b_bppair_win')
        )
        this.soundDictionary.Add(
            SOUNDKEY.BANKER_WIN_PLAYER_PAIR,
            new Sound('b_ppair_win')
        )
        this.soundDictionary.Add(SOUNDKEY.TIE, new Sound('tie'))
        this.soundDictionary.Add(SOUNDKEY.TIE_BANKER_PAIR, new Sound('t_bpair'))
        this.soundDictionary.Add(SOUNDKEY.TIE_BOTH_PAIR, new Sound('t_bppair'))
        this.soundDictionary.Add(SOUNDKEY.TIE_PLAYER_PAIR, new Sound('t_ppair'))
        this.soundDictionary.Add(SOUNDKEY.TIGER_WIN, new Sound('tiger_win'))
        this.soundDictionary.Add(SOUNDKEY.DRAGON_WIN, new Sound('dragon_win'))
        this.soundDictionary.Add(SOUNDKEY.BET_PLACE, new Sound('chip2'))
        this.soundDictionary.Add(SOUNDKEY.BET_SUCCESS, new Sound('bet_success'))
        this.soundDictionary.Add(
            SOUNDKEY.FANTAN_1,
            new Sound(SOUNDKEY.FANTAN_1)
        )
        this.soundDictionary.Add(
            SOUNDKEY.FANTAN_2,
            new Sound(SOUNDKEY.FANTAN_2)
        )
        this.soundDictionary.Add(
            SOUNDKEY.FANTAN_3,
            new Sound(SOUNDKEY.FANTAN_3)
        )
        this.soundDictionary.Add(
            SOUNDKEY.FANTAN_4,
            new Sound(SOUNDKEY.FANTAN_4)
        )

        this.isLoaded = true
    }

    Stop(): void {
        for (let index = 0; index < this.sounds.length; index++) {
            const audio = this.sounds[index]
            if (audio && audio.currentTime > 0) {
                audio.pause()
                audio.currentTime = 0
            }
        }
    }

    Play(soundKey: string): void {
        if (this.isOn && document.hasFocus()) {
            let soundPlayer = this.sounds[0]

            for (let index = 0; index < this.sounds.length; index++) {
                if (!this.IsCurrentlyPlaying(this.sounds[index])) {
                    soundPlayer = this.sounds[index]
                    break
                }
            }

            if (this.soundDictionary.ContainsKey(soundKey)) {
                if (soundPlayer.muted) {
                    soundPlayer.muted = false
                }
                soundPlayer.pause()
                soundPlayer.currentTime = 0

                soundPlayer.volume = this.soundsVolume
                soundPlayer.src = this.soundDictionary.Item(soundKey).src
                const myPromise = soundPlayer.play()

                if (myPromise) {
                    myPromise
                        .then(() => {
                            //
                        })
                        .catch(() => {
                            //
                        })
                }
            }
        }
    }

    IsCurrentlyPlaying(audio: HTMLAudioElement): boolean {
        if (audio.duration > 0 && !audio.paused) {
            return true
        } else {
            return false
        }
    }

    set state(value: number) {
        this.isOn = value
    }

    /**
     * this will initailize the sound
     * a fixed on IOS not playing sound
     * it need to play on fisrt user interation
     */
    Start(): void {
        if (this.isSoundInitialized) return
        this.isSoundInitialized = true
    }

    PlayMusic(): void {
        if (this.soundEffect === 1) {
            const audioMSC = document.getElementById(
                'soundeffect'
            ) as HTMLAudioElement

            const musicSrc = `${
                window.location.origin
            }${require('@/assets/sounds/musicbg.mp3')}`

            if (audioMSC && audioMSC.src !== musicSrc) {
                audioMSC.src = musicSrc
                audioMSC.volume = this.musicVolume
                audioMSC.preload = 'auto'
                audioMSC.muted = false
                audioMSC.load()
            }

            if (audioMSC.paused) {
                audioMSC.muted = false
                audioMSC.play()
            }
        }
    }

    StopMusic(): void {
        const audioMSC = document.getElementById(
            'soundeffect'
        ) as HTMLAudioElement

        if (audioMSC && audioMSC.currentTime > 0) {
            audioMSC.pause()
            audioMSC.currentTime = 0
        } else if (audioMSC && audioMSC.currentTime <= 0) {
            audioMSC.src = ''
            audioMSC.pause()
        }
    }

    SetMusicVolume(num: number) {
        const audioMSC = document.getElementById(
            'soundeffect'
        ) as HTMLAudioElement

        this.musicVolume = num
        if (audioMSC) audioMSC.volume = this.musicVolume
    }
    SetSoundVolume(num: number) {
        this.soundsVolume = num
    }
}

const audioPlayer = new AudioManager()

export default audioPlayer
