import { CookiesToken } from '@/types/CookiesToken'
import {
    getBrowserLanguage,
    getCookieValue,
    getDeviceOrientation,
    isMobile,
    setCookieValue
} from '@/utils'
import { AppState, ChipItem, State } from 'vue'
import { GetterTree, Module, MutationTree } from 'vuex'
import { AppMutation } from '../types'

const state: AppState = {
    device: { display: isMobile(), orientation: getDeviceOrientation() },
    showLoading: false,
    canPlaySound: parseInt(getCookieValue(CookiesToken.SOUND) || '1'),
    isUserInteracted: -1,
    lang: getCookieValue(CookiesToken.LANG) ?? getBrowserLanguage(),
    isfullScreen: false,
    video: parseInt(getCookieValue(CookiesToken.VIDEO) || '1'),
    chips: getCookieValue(CookiesToken.CHIP_ITEMS)?.toObject<number[]>() || [],
    currentChip: parseInt(getCookieValue(CookiesToken.CURRENT_CHIP) || '-1'),
    customChip: parseInt(getCookieValue(CookiesToken.CUSTOM_CHIP) || '0'),
    soundVolume: parseInt(getCookieValue(CookiesToken.SOUND_VOLUME) || '1')
}

const mutations: MutationTree<AppState> = {
    [AppMutation.SET_ORIENTATION](state, payload: 'landscape' | 'portrait') {
        state.device.orientation = payload
    },
    [AppMutation.HIDE_LOADING](state) {
        state.showLoading = false
    },
    [AppMutation.SHOW_LOADING](state) {
        state.showLoading = true
    },
    [AppMutation.SET_SOUND](state, payload: boolean) {
        if (payload) state.canPlaySound = 1
        else state.canPlaySound = 0
        setCookieValue(CookiesToken.SOUND, String(payload))
    },
    [AppMutation.SET_USER_INTERACTION](state) {
        state.isUserInteracted = 1
    },
    [AppMutation.SET_LANG](state, payload: string) {
        if (state.lang !== payload) {
            state.lang = payload
            setCookieValue(CookiesToken.LANG, payload)
        }
    },
    [AppMutation.SET_FULL_SCREEN](state, payload: boolean) {
        state.isfullScreen = payload
    },
    [AppMutation.SET_VIDEO](state, payload: boolean) {
        if (payload) state.video = 1
        else state.video = 0

        setCookieValue(CookiesToken.VIDEO, String(state.video))
    },
    [AppMutation.SET_CHIPS](state, payload: number[]) {
        state.chips = payload
        setCookieValue(CookiesToken.CHIP_ITEMS, JSON.stringify(payload))
    },
    [AppMutation.SET_CURRENT_CHIPS](state, payload: number) {
        state.currentChip = payload
        setCookieValue(CookiesToken.CURRENT_CHIP, String(payload))
    },
    [AppMutation.SET_CUSTOM_CHIP](state, payload: number) {
        state.customChip = payload
        setCookieValue(CookiesToken.CUSTOM_CHIP, String(payload))
    }
}

const getters: GetterTree<AppState, State> = {
    isMobile(state) {
        return state.device.display
    },
    deviceOrientation(state) {
        return state.device.orientation
    },
    showLoading(state) {
        return state.showLoading
    },
    canPlaySound(state) {
        return state.canPlaySound === 1
    },
    isUserInteracted(state) {
        return state.isUserInteracted !== -1
    },
    lang(state) {
        return state.lang
    },
    isfullScreen(state) {
        return state.isfullScreen
    },
    soundEffect(state) {
        return state.canPlaySound
    },
    videoIsOn(state) {
        return state.video === 1
    },
    chips(state) {
        return state.chips
    },
    currentChip(state) {
        return state.currentChip
    },
    customChip(state) {
        return state.customChip
    },
    soundVolume(state) {
        return state.soundVolume
    }
}

export const app: Module<AppState, State> = {
    state,
    mutations,
    getters
}
