export const Lang = ['en', 'kor', 'zh-cn', 'zh-hk']

export enum EventBuss {
    WINDOW_RESIZED = 'WINDOW_RESIZED',
    DIALOG = 'DIALOG',
    REQUEST_FULL_SCREEN = 'REQUEST_FULL_SCREEN',
    TOAST_MESSAGE = 'TOAST_MESSAGE',
    BET_MOUSE_UP = 'BET_MOUSE_UP',
    SESSION_EXPIRED = 'SESSION_EXPIRED',
    CONFIRM_CANCEL_CONTENT = 'CONFIRM_CANCEL_CONTENT',
    SMALL_TOAST = 'SMALL_TOAST'
}

export const OtherApiParams = {
    jm: 1,
    skey: process.env.API_SKEY
}

export const ACT = {
    LOGIN: 1,
    TRIAL: 11,
    LOG_OUT: 17,
    MULTIPLE_DESK_INFO: 2,
    GET_NOTICE_AND_LIMIT: 9,
    SINGLE_DESK_INFO: 3,
    CARD_INFO: 10,
    DO_DT_BET: 7,
    DO_BAC_BET: 6,
    CHANGE_PASS: 12,
    GET_LOG: 13,
    GET_LOG_DETAILS: 15,
    MULTIPLE_BET_DESK_INFO: 18,
    REGISTER: 19,
    FUNTAN_RESULT_INFO: 30,
    DO_FUNTAN_BET: 36,
    PWD_KEY: 'ig156'
}

/**
 * GameState Of a Desk
 */
export const GameRoundState = {
    CLOSED: 1,
    END_OF_ROUND: 4
}

/**
 * Game Result
 */
export const RESULT = {
    RED: 1,
    BLUE: 3,
    GREEN: 2
}
