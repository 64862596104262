import { Assets, ref } from 'vue'
//#region Mobile Assets
const imgSrc: Assets[] = []
//#endregion
//#region  PC Assets
const pcAssets: Assets[] = []
//#endregion

const commonAssets: Assets[] = []

export class AssetsManager {
    refVar = ref<{ isLoaded: boolean; loadCount: number; assetCount: number }>()

    /**
     *
     */
    constructor(_refVar: {
        isLoaded: boolean
        loadCount: number
        assetCount: number
    }) {
        this.refVar.value = _refVar
        this.refVar.value.assetCount = imgSrc.length
    }

    private LoadAssest = (_assets: Assets) => {
        return new Promise((resolve, reject) => {
            if (_assets.type === 'image') {
                const img = new Image()
                img.src = _assets.src
                img.onload = () => {
                    if (this.refVar.value) {
                        this.refVar.value.loadCount++
                        if (
                            this.refVar.value.assetCount ===
                            this.refVar.value.loadCount
                        ) {
                            this.refVar.value.isLoaded = true
                        }
                    }
                    resolve(img)
                }
                img.onerror = img.onabort = () => {
                    reject(_assets)
                }
            }
        })
    }

    Preload() {
        const assets = [...imgSrc, ...pcAssets, ...commonAssets]
        assets.map((imgs) => this.LoadAssest(imgs).catch((err) => err))
    }
}
